<template>
  <div>
    <go-top
        bg-color="#36b5aa"
        src="/assets/img/icon-backtotop.png"
        :size="50"
        :has-outline="false"
    ></go-top>
    <div infinite-wrapper>
      <masonry id="infinite-list"
               :cols="{ default: cols, 1000: 3, 700: 2, 400: 1 }"
               :gutter="{ default: gutter+'px', 700: '15px' }"
      >
        <div
            v-for="(image, $index) in images"
            :key="$index"
            class="margin-lg-30b" :style="{'margin-bottom':gutter+'px'}"
        >
          <v-img
              class="grey lighten-2"
              @click="openModal(image)"
              :alt="image.title"
              :src="image.thumb_path"
              :lazy-src="image.thumb_path"
              style="cursor: pointer;"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </masonry>
      <b-modal ref="imageModal" id="scroll" scrollable centered size="xl" hide-footer @hide="handleModalClose">
        <template #modal-header>
          <button href="javascript:void(0)" class="close-btn" @click="$refs.imageModal.hide()">
            <b-icon icon="x" aria-label="Close" variant="dark"></b-icon>
          </button>
          <v-container>
            <v-row justify="space-between">
              <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-start">
                <b-media class="d-flex">
                  <template #aside>
                    <b-avatar button rounded="sm" :src="selectedImage.user.avatar"></b-avatar>
                  </template>
                  <div class="lh-sm">
                    <h5 class="m-0 fs-6 lh-sm">{{ selectedImage.user.fullName }}</h5>
                    <small class="m-0 text-muted">{{ selectedImage.user.profession }} - {{
                        selectedImage.user.location
                      }}</small>
                  </div>
                </b-media>
              </v-col>
              <template v-if="selectedImage.title">
                <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-center" style="white-space: nowrap;">
                  <h5 class="m-0 p-0 fs-6">{{ selectedImage.title }}</h5>
                </v-col>
              </template>
              <v-col :cols="getTitleCols()" sm="12" :md="getTitleCols()" class="text-end">
                <template v-if="selectedImage.allowComments">
                  <b-button variant="light" @click="getAnchorLink('imageDown')">
                    <b-icon icon="chat-left-dots" font-scale="0.9" class="me-1" aria-label="Like" variant="dark"></b-icon>
                    {{ selectedImage.countComments }}
                    <template v-if="selectedImage.countComments > 1">commentaires</template>
                    <template v-else>commentaire</template>
                  </b-button>
                </template>
                <b-button variant="light" @click="getAnchorLink('imageDown')">
                  <b-icon icon="info-circle" font-scale="0.9" class="me-1" aria-label="Like" variant="dark"></b-icon>
                  Infos
                </b-button>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <viewer-image-common :selectedImage="selectedImage"></viewer-image-common>
        <div id="scroll-imageDown"></div>
      </b-modal>
      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
const routes = require("../../../../public/js/fos_js_routes.json");
import Routing from "../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

Routing.setRoutingData(routes);
import VLazyImage from "v-lazy-image";
import $ from "jquery";
import axios from "axios";
import ViewerImageCommon from "../ViewerImageCommon";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  props: ["cols", "gutter"],
  components: {
    VLazyImage,
    "viewer-image-common": ViewerImageCommon,
  },
  data: function () {
    return {
      images: [],
      total: 0,
      page: 1,
      loading: true,
      selectedImage: null,
    };
  },
  methods: {
    getTitleCols() {
      return this.selectedImage.title ? 4 : 6;
    },
    handleModalClose() {
      // Revert the URL back to its original state using the router's replace method
      this.$router.replace({path: '/'});
      this.enableBodyScroll();
    },
    openModal(image) {
      this.selectedImage = image;
      this.$refs.imageModal.show();
      this.disableBodyScroll();
      this.$router.replace(`/fr/image/${image.id}`);
      $.ajax({
        url: Routing.generate("portfolio_setviewmedias", {
          name: name,
          image: image.id,
        }),
        type: "POST",
        data: image.id,
        success: function (response) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(textStatus, errorThrown);
        },
      });
    },
    disableBodyScroll() {
      document.body.style.overflow = "hidden";
    },
    enableBodyScroll() {
      document.body.style.overflow = "auto";
    },
    getAnchorLink(anchorId) {
      const anchor = document.getElementById(`${this.$refs.imageModal.id}-${anchorId}`);
      if (anchor) {
        anchor.scrollIntoView({behavior: 'smooth'});
      }
    },
    infiniteHandler($state) {
      const api = Routing.generate("portfolio_medias", {
        name: name,
        page: this.page,
      });

      axios.get(api).then(({data}) => {
        if (data.images.length > 0) {
          this.page += 1;
          this.images.push(...data.images);
          if (this.page > data.totalPages) {
            $state.complete();
          }
          $state.loaded();
        } else {
          $state.complete();
        }
      });

    },
  },
};
</script>
<style lang="css" scoped src="../modal.css"></style>